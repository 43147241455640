import React from 'react'
import { Helmet } from 'react-helmet';
import Layout from '../components/layout'

import { Link, graphql } from 'gatsby'

// import Svg404 from '../images/svgs/f404.svg'

function Kontakt(props) {
  const siteTitle = props.data.site.siteMetadata.title
  

  return (
    <Layout>
      <Helmet
        title={`404 | ${siteTitle}`}
      />
      <section className="is-spaced-top-margin about">
        <div className="container">
          <div className="columns is-mobile-reversed">
            <div className="column is-6">
              <h1 className="title is-1">Leider konnten wir diese Seite nicht finden...</h1>
              <Link to="/" className="button is-info">Zur Startseite</Link>
            </div>
            <div className="column is-6">
              {/* <Svg404 /> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Kontakt

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`